import axios from "axios"
import userMixin from "@/mixins/userMixin"
import siteMixin from "@/mixins/siteMixin"
// import axiosInstance from "./api";
// import TokenService from "./token.service";

const setup = (store) => {
    axios.interceptors.request.use(
        async (request) => {

            const siteType = siteMixin.methods.getSiteType();

            // check if user has permission to make request
            const requiredPermission = request.headers['X-Required-Permission'];
            const site = siteMixin.methods.getSite();

            // console.log(requiredPermission)

            if (requiredPermission && !userMixin.methods.userHasPermission(requiredPermission, false, site.type)) {
                // You can handle this based on your application's logic,
                // such as showing an error message or redirecting
                // throw new Error('Unauthorized request');
                // return Promise.reject(new Error('Unauthorized request'));
                // return Promise.resolve({
                    //     data: {error: 'Unauthorized'},
                    //     status: 403, // Forbidden status code
                    //     statusText: 'Forbidden',
                    //     headers: request.headers,
                    //     config: request,
                    //     method: request.method,
                    //     baseURL: request.baseURL,
                    //     url: request.url
                    // });
                    // return Promise.reject({
                        //     response: {
                            //         data: { error: 'Unauthorized' },
                            //         status: 403,
                            //         statusText: 'Forbidden',
                            //         headers: request.headers,
                            //         config: request,
                            //         request: null, // Set request to null to stop execution
                            //     }
                            // });
                request.adapter = function (config) {
                    return new Promise((resolve) => {
                        const res = {
                            data: [],
                            status: 200,
                            statusText: "OK",
                            headers: { "content-type": "text/plain; charset=utf-8" },
                            config,
                            request: {}
                        };

                        return resolve(res);
                    });
                }
            }

            if (siteType == 'cms6') {
                request = await fileUploadChecker(request, siteType);
                if (request.data && request.data.type && request.data.type == 'error') {
                    return Promise.reject(request);
                }
                let site = siteMixin.methods.getSite();
                let doNotAddClient = request.doNotAddClient ?? false;

                if (site && site.id && !doNotAddClient) {

                    if (request.method == 'post') {
                        if (isJson(request.data)) {
                            request.data = JSON.parse(request.data);
                        }
                        request.data.client_id = site.id;
                    }

                    if (!request.url.includes(process.env.VUE_APP_HEYGOV_API) && !request.url.includes(process.env.VUE_APP_HEYGOV_FILES)) {

                        request.url = request.url + '?client_id=' + site.id;
                    }

                }
            }

            return request;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

   axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {

            if (err.data && err.data.type && err.data.type == 'error') {
                return Promise.resolve(err);
            }

            const config = err.config;

            if (err.response && err.response.status === 401) {



                userMixin.methods.logout(false);
                store.commit('showLoginModal');

                let auth = await getNewAuth(userMixin);

                config.headers['Authorization'] = 'Bearer ' + auth;
                config._retry = true;

                return await axios.request(config);
            }

            return Promise.reject(err);
        }
    );

    const getNewAuth = (userMixin) => {
        return new Promise((resolve) => {


            const checkAuth = (userMixin) => {
                let user = userMixin.methods.getCurrentUser();
                let auth = user ? user.auth : null;

                if (auth) {
                    resolve(auth);
                } else {
                    setTimeout(checkAuth,1000, userMixin);
                }
            }

            checkAuth(userMixin);
        })
    }

    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const fileUploadChecker = (request) => {

        return new Promise((resolve) => {


            const checkFile = async (request) => {
                if (request.method == 'post' && request.data instanceof FormData && request.data.has('file')) {

                    let file = request.data.get('file');

                    let fileSizeMB = file.size/(1024*1024);

                    if (fileSizeMB >= 32) {

                        // check image size
                        if (file.type && file.type.startsWith('image/')) {
                            console.log(file.size);
                            resolve({data: {type: 'error', errors: {file: ["Image file size must be lower then 32MB."]}}})
                        }
                        // only cms6 for now, without heygov
                        if (!request.url.includes(process.env.VUE_APP_HEYGOV_API) && !request.url.includes(process.env.VUE_APP_HEYGOV_FILES)) {
                            // console.log(fileSizeMB)
                            let user = userMixin.methods.getCurrentUser();
                            let auth = user.auth;
                            let headers = { Authorization: "Bearer " + auth };
                            // request for signed url
                            const res = await axios.post(process.env.VUE_APP_JUNIPER_API + 'media/signUrl', {filename: file.name, type: file.type, size: file.size}, {headers: headers});
                            // const res = await axios.post(process.env.VUE_APP_JUNIPER_API + 'signUrl', {filename: file.name, type: file.type}, {headers: headers});
                            if(res.data && res.data.type == 'error') {
                                resolve(res);
                            }
                            let signedUrl = res.data.url;

                            try {
                                // request for google cloud storage direct upload
                                const res2 = await axios.put(signedUrl, file, {doNotAddClient: true, headers: {"Content-Type": file.type}});

                                if (res2.status && res2.status == 200) {

                                    let site = siteMixin.methods.getSite();

                                    let data = {filename: file.name, type: file.type, path: res.data.path, size: file.size};

                                    if (site && site.id) {
                                        data = {...data, ...{client_id: site.id}}

                                    }

                                    request = {
                                        url: process.env.VUE_APP_JUNIPER_API + 'media/storeWithoutUpload',
                                        method: 'post',
                                        headers: {...headers, ...{"Content-Type": "application/json"}},
                                        data: JSON.stringify(data),
                                        doNotAddClient: true
                                    }
                                }

                            } catch (error) {
                                console.log(error)
                            }
                        }
                    }

                }

                resolve(request);
            }

            checkFile(request);
        })
    }
};

export default setup;
