import { createApp } from "vue";
import { createStore } from "vuex";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "bootstrap";
import moment from "moment-timezone";
import VueSweetalert2 from "vue-sweetalert2";

// directives
import { permission } from '@/directives/permission';

import setupInterceptors from "./services/setupInterceptors";

import Datepicker from "vue3-date-time-picker";

import "vue3-date-time-picker/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/main.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/multiselect/themes/default.css";

// tracing
import Tracker from "@openreplay/tracker";
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

let tracker;

if (process.env.NODE_ENV == "production") {
    tracker = new Tracker({
        projectKey: "JeP5TdQUSkoQtV8Gt0vg",
        ingestPoint: "https://openreplay.townweb.com/ingest",
        respectDoNotTrack: true,
        obscureTextEmails: false,
        obscureInputEmails: false,
        defaultInputMode: 0,
        network: {
            capturePayload: true // Capture HTTP payload
        }
        // onStart: ({ sessionToken }) => {
        // This is to link from OpenReplay -> Sentry
        // Sentry.setTag("openReplaySessionToken", sessionToken);

        // This is to link from Sentry -> OpenReplay (requires tracker v3.6.0+)
        // Sentry.setTag(
        //     "openReplaySessionURL",
        //     tracker.getSessionURL()
        // );
        // },
    });
    tracker.start();
}

// Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

import { faPlus, faMinus, faStar, faArrowUp, faArrowDown, faChevronUp, faChevronDown, faCog, faFolderOpen, faFolder, faQuestion, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

library.add({
    faPlus,
    faMinus,
    faStar,
    faArrowUp,
    faArrowDown,
    faChevronUp,
    faChevronDown,
    faCog,
    faFolderOpen,
    faFolder,
    faQuestion,
    faEye,
    faEyeSlash,
});

Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
    return JSON.parse(this.getItem(key));
};

let userName = 'init';
let siteUrl = 'init';

if (process.env.NODE_ENV == "production") {
    let user = sessionStorage.getObject('user') || localStorage.getObject('user');
    let site = localStorage.getObject('site');


    if (user) {
        userName = user.data.email || user.data.name;
    }

    if (site) {
        siteUrl = site.url;
    }

    tracker.setUserID( userName + '|' + siteUrl );
}

// Create a new store instance.
const store = createStore({
    state() {
        return {
            overlayShow: false,
            page: {},
            loginModalShow: false,
        };
    },
    mutations: {
        showOverlay(state) {
            state.overlayShow = true;
        },
        hideOverlay(state) {
            state.overlayShow = false;
        },
        setPage(state, { page }) {
            state.page = page;
        },
        showLoginModal(state) {
            state.loginModalShow = true;
        },
        hideLoginModal(state) {
            state.loginModalShow = false;
        },
    },
    getters: {
        overlayShow(state) {
            return state.overlayShow;
        },
        page(state) {
            return state.page;
        },
        loginModalShow(state) {
            return state.loginModalShow;
        },
    },
});

setupInterceptors(store);

// VueSweetalert2 options
const options = {
    confirmButtonColor: "#0FCB4F",
    cancelButtonColor: "#EC2641",
};

const app = createApp(App).use(router).use(moment).use(store).use(VueSweetalert2, options);

app.config.globalProperties.axios=axios
app.config.globalProperties.moment=moment
if (process.env.NODE_ENV == "production") {
    app.config.globalProperties.tracker=tracker
}

app.component("font-awesome-icon", FontAwesomeIcon);

app.component("Datepicker", Datepicker);

app.directive('permission', permission);

// Sentry.init({
//     app,
//     dsn: "https://6c0d633636754a56a8db84a3653e3f83@o4504321338572800.ingest.sentry.io/4504322911371264",
//     integrations: [
//         new BrowserTracing({
//           routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//           tracePropagationTargets: ["localhost", "dashboard.townweb.com/", /^\//],
//           // tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
//         }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

app.mount("#app");
