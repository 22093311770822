import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue"
import userMixin from "@/mixins/userMixin";
import siteMixin from "@/mixins/siteMixin";

const routes = [
    {
        path: "/",
        name: "Home",
        // meta: {
        //     title: 'Dashboard - Town Web',
        //     metaTags: [
        //         {
        //             name: 'description',
        //             content: 'The home page of our example app.'
        //         },
        //         {
        //             property: 'og:description',
        //             content: 'The home page of our example app.'
        //         }
        //     ]
        // },
        components: {
            // a: Home
            a: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
        },
    },
    {
        path: "/events",
        name: "Events",
        meta: { permission: 'view_events'},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ '../views/Events.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/events/Events.vue"),
        },
    },
    {
        path: "/events/events-style",
        name: "EventsStyle",
        meta: { permission: 'view_events'},
        components: {
            a: () => import("../views/events/style/EventsStyle.vue"),
        },
    },
    {
        path: "/events/event-location/:uuid",
        name: "EventLocation",
        meta: {
            play_modal: true,
            permission: 'view_events'
        },
        components: {
            a: () => import("../views/events/EventLocation.vue"),
        },
    },
    {
        path: "/events/events-list",
        name: "EventsList",
        meta: { permission: 'view_events'},
        components: {
            a: () => import("../views/events/EventsList.vue"),
        },
    },

    {
        path: "/meeting-repository",
        name: "Meeting repository",
        meta: { permission: 'view_meeting_repository'},
        // component: () => import(/* webpackChunkName: "about" */ '../views/MeetingRepository.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/MeetingRepository.vue"),
        },
    },
    {
        path: "/pages",
        name: "Pages",
        meta: { permission: 'view_pages', model: 'pages'},
        // component: () => import(/* webpackChunkName: "about" */ '../views/Pages.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/Pages.vue"),
        },
    },
    {
        path: "/news-notices",
        name: "News&Notices",
        meta: { permission: 'view_news_notices', model: 'news_notices'},
        // component: () => import(/* webpackChunkName: "about" */ '../views/NewsNotices.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/NewsNotices.vue"),
        },
    },
    {
        path: "/departments",
        name: "Departments",
        meta: { permission: 'view_departments', model: 'departments'},
        // component: () => {
        //     if (siteMixin.methods.doesSupportDepartments()) {
        //         return import(/* webpackChunkName: "departments" */ '../views/Departments.vue')
        //     } else {
        //         return import(/* webpackChunkName: "404" */ '../views/404.vue')
        //     }
        // },
        components: {
            a: () => {
                if (siteMixin.methods.doesSupportDepartments()) {
                    return import(/* webpackChunkName: "departments" */ "../views/Departments.vue");
                } else {
                    return import(/* webpackChunkName: "404" */ "../views/404.vue");
                }
            },
        },
    },
    {
        path: "/departments/create",
        name: "CreateDepartment",
        meta: { permission: 'create_departments'},
        // component: () => {
        //     if (siteMixin.methods.doesSupportDepartments()) {
        //         return import( /* webpackChunkName: "departments" */ '../views/single/Department.vue')
        //     } else {
        //         return import(/* webpackChunkName: "404" */ '../views/404.vue')
        //     }
        // }
        components: {
            a: () => {
                if (siteMixin.methods.doesSupportDepartments()) {
                    return import(/* webpackChunkName: "departments" */ "../views/single/Department.vue");
                } else {
                    return import(/* webpackChunkName: "404" */ "../views/404.vue");
                }
            },
        },
    },
    {
        path: "/departments/:slug",
        name: "SingleDepartment",
        meta: { permission: 'view_departments', model: 'departments'},
        // component: () => {
        //     if (siteMixin.methods.doesSupportDepartments()) {
        //         return import( /* webpackChunkName: "departments" */ '../views/single/Department.vue')
        //     } else {
        //         return import(/* webpackChunkName: "404" */ '../views/404.vue')
        //     }
        // },
        components: {
            a: () => {
                if (siteMixin.methods.doesSupportDepartments()) {
                    return import(/* webpackChunkName: "departments" */ "../views/single/Department.vue");
                } else {
                    return import(/* webpackChunkName: "404" */ "../views/404.vue");
                }
            },
        },
    },
    {
        path: "/faqs",
        name: "Faqs",
        meta: { permission: 'view_faqs', model: 'faqs'},
        components: {
            a: () => {
                if (siteMixin.methods.getSiteType() == "cms6") {
                    return import(/* webpackChunkName: "faqs" */ "../views/Faqs.vue");
                } else {
                    return import(/* webpackChunkName: "404" */ "../views/404.vue");
                }
            },
        },
    },
    {
        path: "/account-settings",
        name: "AccountSettings",
        meta: { permission: 'view_dashboard_account_settings'},
        // component: () => import(/* webpackChunkName: "about" */ '../views/AccountSettings.vue'),
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/AccountSettings.vue"),
        },
        children: [
            {
                // A will be rendered in the second <router-view>
                // when /your-sidebar-url/a is matched
                path: "client-info",
                name: "ClientInfo",
                components: {
                    b: () => import(/* webpackChunkName: "about" */ "../views/account-settings/ClientInfo.vue"), //note that 'b' is the name of child router view
                },
            },
            {
                // B will be rendered in the second <router-view>
                // when /your-sidebar-url/b is matched
                path: "contract-info",
                name: "ContractInfo",
                components: {
                    b: () => import(/* webpackChunkName: "about" */ "../views/account-settings/SubscriptionInfo.vue"), //note that 'b' is the name of child router view
                },
            },
            {
                // B will be rendered in the second <router-view>
                // when /your-sidebar-url/b is matched
                path: "billing-info",
                name: "BillingInfo",
                components: {
                    b: () => import(/* webpackChunkName: "about" */ "../views/account-settings/BillingInfo.vue"), //note that 'b' is the name of child router view
                },
            },
            {
                // B will be rendered in the second <router-view>
                // when /your-sidebar-url/b is matched
                path: "website-insight",
                name: "WebsiteInsight",
                components: {
                    b: () => import(/* webpackChunkName: "about" */ "../views/account-settings/WebsiteInsight.vue"), //note that 'b' is the name of child router view
                },
            },
            {
                // B will be rendered in the second <router-view>
                // when /your-sidebar-url/b is matched
                path: "emails",
                name: "Emails",
                components: {
                    b: () => import(/* webpackChunkName: "about" */ "../views/account-settings/Emails.vue"), //note that 'b' is the name of child router view
                },
            },
        ],
    },
    {
        path: "/media-library",
        name: "MediaLibrary",
        meta: { permission: 'view_media_library'},
        // component: () => import(/* webpackChunkName: "about" */ '../views/MediaLibrary.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/MediaLibrary.vue"),
        },
    },
    {
        path: "/page-builder",
        name: "PageBuilder",
        meta: { permission: 'create_pages'},
        // component: () => import(/* webpackChunkName: "about" */ '../views/Pages.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/page-builder/PageBuilder.vue"),
        },
    },
    {
        path: "/page-builder/:id",
        name: "PageBuilderEdit",
        meta: { permission: 'update_pages'},
        // component: () => import(/* webpackChunkName: "about" */ '../views/Pages.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/page-builder/PageBuilder.vue"),
        },
    },
    {
        path: "/subscribers",
        name: "Subscribers",
        meta: { permission: 'view_subscribers'},
        // component: () => import(/* webpackChunkName: "about" */ '../views/MediaLibrary.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/Subscribers.vue"),
        },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        // component: () => import(/* webpackChunkName: "about" */ '../views/MediaLibrary.vue')
        components: {
            c: () => import(/* webpackChunkName: "about" */ "../views/ResetPassword.vue"),
        },
    },
    {
        path: "/403",
		name: 'Page403',
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/403.vue"),
        },
    },
    // and finally the default route, when none of the above matches:
    {
        path: "/:pathMatch(.*)*",
        // component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
        components: {
            a: () => import(/* webpackChunkName: "about" */ "../views/404.vue"),
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    // const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Object.keys(tagDef).forEach(key => {
    // tag.setAttribute(key, tagDef[key]);
    // });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    // tag.setAttribute('data-vue-router-controlled', '');

    // return tag;
    // })
    // Add the meta tags to the document head.
    // .forEach(tag => document.head.appendChild(tag));

    // Remove any stale meta tags from the document using the key attribute we set below.
    // Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    // if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    // nearestWithMeta.meta.metaTags.map(tagDef => {
    // const tag = document.createElement('meta');

    // Object.keys(tagDef).forEach(key => {
    // tag.setAttribute(key, tagDef[key]);
    // });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    // tag.setAttribute('data-vue-router-controlled', '');

    // return tag;
    // })
    // Add the meta tags to the document head.
    // .forEach(tag => document.head.appendChild(tag));

    // if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    let user = userMixin.methods.getCurrentUser();
    if (!user && to.name != "Home" && to.name != "ResetPassword") {
        next({ name: "Home" });
    } else {
        if (to.meta.permission) {
            const permission = to.meta.permission;
            const model = to.meta.model || null;
            const site = localStorage.getObject('site');
            if (!userMixin.methods.userHasPermission(permission, false, site.type, model)) {
                next({name: 'Page403', params: { pathMatch: to.fullPath }});
                return;
            }
        }

        next(); // go to wherever I'm going
    }
    // } else {
    //   next() // does not require auth, make sure to always call next()!
    // }
});

export default router;
